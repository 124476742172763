<template>
    <div>

      <div class="mobile-header">
          <div class="mobile-header__humburger" @click="isOpenMenu = !isOpenMenu">
            <img src="../assets/img/hamburger.svg">
            <span> Меню </span>
          </div>
          <div class="mobile-header__logo">
            <img src="../assets/img/logo-mobile.svg">
          </div>
      </div>

      <div class="menu" :class="{'open' : isOpenMenu}">

        <router-link to="/" class="logo">
          <img src="../assets/img/logo-min.svg" class="logo--1">
          <img src="../assets/img/logo-max.svg" class="logo--2">
        </router-link>

        <div class="nav">
          <router-link :to="{name: 'PageOrganizations'}" class="nav__item">
            <IconPageOrganization />
            <div class="nav__name">Управляющие организации</div>
          </router-link>
          <router-link to="/houses" class="nav__item" v-if="roleId !== 'admin'">
            <IconPageHouses />
            <div class="nav__name">Дома</div>
          </router-link>
          <router-link to="/employees" class="nav__item" v-if="roleId !== 'admin'">
            <IconPageEmployees />
            <div class="nav__name">Сотрудники</div>
          </router-link>
          <router-link :to="{name: 'PageReports'}" class="nav__item">
            <IconPageReports />
            <div class="nav__name">Отчёты</div>
          </router-link>
          <router-link :to="{name: 'PageTracker'}" class="nav__item">
            <IconPageTracker />
            <div class="nav__name">Трекер заданий</div>
          </router-link>
          <a href="https://docs.google.com/document/d/1ySqQgEqMU2BGoqh9gbMtalYC-AoR82Q3J3Pg8nT-X90/edit" target="_blank" class="nav__item">
            <IconPageInstructions />
            <div class="nav__name">Инструкция</div>
          </a>
        </div>
      </div>
    </div>
</template>

<script setup>
  import IconPageOrganization from '@/components/icons/pages/IconPageOrganization.vue';    
  import IconPageHouses from '@/components/icons/pages/IconPageHouses.vue';    
  import IconPageEmployees from '@/components/icons/pages/IconPageEmployees.vue';  
  import IconPageReports from '@/components/icons/pages/IconPageReports.vue';  
  import IconPageTracker from '@/components/icons/pages/IconPageTracker.vue'; 
  // import IconPageNotFound from '@/components/icons/pages/IconPageNotFound.vue';
  import IconPageInstructions from '@/components/icons/pages/IconPageNotFound.vue';
</script>
<script>
  export default {
    name: 'LeftMenu',
    data: () => ({
      isOpenMenu: false,
    }),
    watch:{
      $route (){
        this.isOpenMenu = false;
        window.scrollTo(0,0);
      }
    },
    computed: {
      roleId () {
        return this.$store.state.auth.user.roleId;
      }
    },

  }
</script>


<style scoped>

  .menu {
    position: fixed;
    background-color: var(--cozh-white);
    width: 90px;
    min-width: 90px;
    display: flex;
    flex-direction: column;
    height: 100%;
    user-select: none;
    z-index: 5;
    transition: all .2s ease-out;
    overflow-x: hidden;
  }

  .logo {
    display: flex;
    align-items: center;
    justify-content: left;
    padding: 30px 30px;
  }
  .logo img {
    height: 28px;
  }
  .logo a {
    display: flex;
  }


  .nav {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px;
    width: 100%;
    border-top: 1px solid var(--cozh-light-grey);

  }
  .nav__item {
    min-width: 100%;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: left;
    border-radius: 4px;
    padding: 16px;
  }
  .nav .nav__item:not(:last-child) {
    margin-bottom: 5px;
  }
  .nav__item:hover {
    background-color: rgba(var(--cozh-blue-code), .08);
  }
  .nav__item.router-link-active {
    background-color: rgba(var(--cozh-blue-code), .08);
  }
   .nav__item:deep(svg path) {
/*    transition: all .2s ease-out;*/
  }
  .nav__item.router-link-active:deep(svg path) {
    fill: var(--cozh-blue);
  }


  .nav__name {
    display: none;
    margin-left: 15px;
    font-weight: 600;
    font-size: 12px;
    line-height: 1;
  }


  .logo--2 {
    display: none;
  }


  .menu:hover {
    width: 300px;
    box-shadow: 20px 0px 60px 0px rgba(var(--cozh-black-code), 0.15);
  }
  .menu:hover .logo .logo--1 {
    display: none;
  }
  .menu:hover .logo .logo--2 {
    display: block;
  }
  .menu:hover .nav {
    width: 300px;
  }
  .menu:hover .nav__name {
    display: block;
  }
  .menu:hover .nav__item {
    width: 100%;
  }


  .mobile-header {
    height: 60px;
    background-color: var(--cozh-white);
    width: 100%;
    display: none;
    padding: 0px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    display: none;

  }

  .mobile-header__logo img {
    display: flex;
  }

  .mobile-header__humburger {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0px 20px;
    margin-left: -20px;
    height: 100%;
  }
  .mobile-header__humburger img {
    margin-right: 10px;
  }

  .mobile-header__humburger span {
    font-size: 11px;
    font-weight: 700;
    line-height: 14px;
    letter-spacing: 0.07em;
    text-transform: uppercase;
  }


/*  @media (max-width: 767px) {
    .nav {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
    .nav__item {
      border-radius: 8px;
      padding: 13px 20px;
    }
    .nav .nav__item:not(:last-child) {
      margin-bottom: 0px;
    }
    .menu, .menu:hover {
      width: 100%;
      box-shadow:  none;
      left: calc(-100%);
      top: 60px;
      height: calc(100% - 60px);
      position: absolute;
    }
    .menu:hover .nav {
      width: 100%;
    }
    .logo {
      display: none;
    }
    .menu .nav__name {
      display: block;
    }
    .menu.open {
      left: 0px;
    }
    .mobile-header {
      display: flex;
    }
    .nav__name {
      font-size: 14px;
    }
  }*/


</style>
